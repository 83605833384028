<template>
    <div>
        <RecomendationTable>
            
        </RecomendationTable>
    </div>
</template>


<script>
import RecomendationTable from "@/views/specialists/sub-modules/financial-approval/views/recomendations/views/components/RecomendationsTable.vue"

export default {
    components: {
        RecomendationTable
    }

}
</script>